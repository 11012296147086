<template>
  <div>
    <component :is="TopBarComponent" v-if="isDesktop"></component>
    <Navbar />
    <slot />
    <Footer />
    <ClientOnly>
      <LoginModal />
      <RegisterModal />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, defineAsyncComponent } from "vue";
import { storeToRefs } from "pinia";
import useClientTypeStore from "~/stores/clientType";
import { useReport } from "~/composables/useReport";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";
import { useAsyncData, useRequestHeaders } from "#imports";
import useConfigStore from "~/stores/config";
import toast, { initToast } from "~/lib/alert";

initToast({
  x: "right",
  y: "top",
});
const configStore = useConfigStore();
const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);
const RegisterModal = defineAsyncComponent(
  () => import("~/section/common/RegisterModal.vue")
);
const LoginModal = defineAsyncComponent(
  () => import("~/section/common/LoginModal.vue")
);
const Navbar = defineAsyncComponent(() => import("~/section/Navbar.vue"));
const Footer = defineAsyncComponent(
  () => import("~/section/common/Footer.vue")
);
const TopBarComponent = defineAsyncComponent(
  () => import("~/section/navbar/Topbar.vue")
);

async function determineClientType() {
  const headers = useRequestHeaders();
  const userAgent = headers["user-agent"];
  await clientTypeStore.determineClientType(userAgent);
}

async function getConfig() {
  const { data, pending, error, refresh } = await useAsyncData(
    "getConfig",
    () => configStore.getConfig()
  );
  if (data.value) {
    const { success, message } = data.value;
    if (!success) {
      toast.error(message);
    }
  }
}

async function initCredentials() {
  try {
    const useUserStore = (await import("~/stores/user")).default;
    const userStore = useUserStore();
    const { storageGetAccessToken } = await import("~/composables/accessToken");
    userStore.accessToken = storageGetAccessToken().data || "";
    if (userStore.accessToken) {
      const { fetchProfile } = await import("~/services/user/profile");
      fetchProfile();
    }
  } catch (err) {
    useReport({
      level: "error",
      message: `${DEFAULT_ERROR_MESSAGE}, failed to init credential`,
      errorException: err,
    });
  }
}

async function fetchCity() {
  try {
    const useCityStore = (await import("~/stores/city")).default;
    useCityStore().fetchCity();
  } catch (err) {
    useReport({
      errorException: err,
      level: "error",
      message: `${DEFAULT_ERROR_MESSAGE}, failed load city store`,
    });
  }
}

await determineClientType();
await getConfig();

onMounted(() => {
  initCredentials();
  fetchCity();
});
</script>

<style lang="scss" scoped>
.bg-overlay-gray {
  background: linear-gradient(90deg, #ffffff 54.72%, #ededed 64.13%);
}
</style>
